import React from "react";
// import Identification from "./views/pages";
import { ToastContainer } from "react-toastify";
// import DimsMobile from "./views/pages/dimsmobile";
// import Riskcovry from "./views/pages/riskcovry";
// import AirtelPaymentBank from "./views/pages/apb";
import MedicalMobile from "./views/pages/medicalmobile";

const App = () => {
  return (
    <div>
      {/* <Identification /> */}
      {/* <AirtelPaymentBank /> */}
      {/* <Riskcovry /> */}
      {/* <DimsMobile /> */}
      <MedicalMobile />
      <ToastContainer position="top-right" />
    </div>
  );
};

export default App;
